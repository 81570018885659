import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import defaultAvatar from "../assets/images/avatar.png"; // Adjust the path as needed
import {
  FaArrowLeft,
  FaEdit,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaGlobe,
  FaSpinner,
} from "react-icons/fa";

const UserDetails = () => {
  const { userId } = useParams(); // Extract the userId from the URL
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${backendUrl}/users/users/${userId}`);
        setUserData(response.data);
      } catch (err) {
        console.error("Error fetching user details:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserDetails();
    }
  }, [userId, backendUrl]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex items-center space-x-2">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
          <p className="text-xl text-gray-700">Loading user details...</p>
        </div>
      </div>
    );
  }

  if (error || !userData) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <p className="text-2xl text-red-500 mb-4">
          Failed to load user details.
        </p>
        <Link
          to="/"
          className="flex items-center text-blue-500 hover:underline text-lg"
        >
          <FaArrowLeft className="mr-2" /> Go Back
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      {/* Main Content */}
      <div className="flex flex-1">
        {/* User Details Section */}
        <main className="flex-1 p-6 mt-16">
          <div className="bg-white rounded-lg p-8">
            <div className="flex flex-col md:flex-row items-center md:items-start">
              <img
                src={
                  userData.avatar
                    ? `${backendUrl2}/${userData.avatar}`
                    : defaultAvatar
                }
                alt={`${userData.firstname} ${userData.lastname}`}
                className="w-40 h-40 object-cover rounded-full mb-6 md:mb-0 md:mr-8"
              />
              <div>
                <h1 className="text-3xl font-bold mb-2">
                  {userData.title} {userData.firstname} {userData.lastname}
                </h1>
                <p className="text-gray-600 mb-4">@{userData.username}</p>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <FaEnvelope className="text-gray-500 mr-2" />
                    <a
                      href={`mailto:${userData.email}`}
                      className="text-blue-600 hover:underline"
                    >
                      {userData.email}
                    </a>
                  </div>
                  <div className="flex items-center">
                    <FaPhone className="text-gray-500 mr-2" />
                    <a
                      href={`tel:${userData.phone}`}
                      className="text-blue-600 hover:underline"
                    >
                      {userData.phone}
                    </a>
                  </div>
                  {userData.address && (
                    <div className="flex items-center">
                      <FaMapMarkerAlt className="text-gray-500 mr-2" />
                      <span className="text-gray-700">{userData.address}</span>
                    </div>
                  )}
                  {/* Add more contact fields as necessary */}
                </div>
              </div>
            </div>

            {/* Additional Information */}
            <div className="mt-8">
              <h2 className="text-2xl font-semibold mb-4">About</h2>
              <p className="text-gray-700">
                {userData.bio || "This user has not provided a bio."}
              </p>
            </div>

            {/* Properties */}
            <div className="mt-8">
              <h2 className="text-2xl font-semibold mb-4">Properties</h2>
              <p className="text-gray-700">
                {userData.properties || "This user has not added properties."}
              </p>
            </div>

            {/* reviews */}
            <div className="mt-8">
              <h2 className="text-2xl font-semibold mb-4">Reviews</h2>
              <p className="text-gray-700">
                {userData.reviews || "This user has not been reviewed"}
              </p>
            </div>

            {/* You can add more sections like Projects, Activity, etc. */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserDetails;
