// src/components/Dashboard.js

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  FiSearch,
  FiEdit,
  FiCheckCircle,
  FiXCircle,
  FiMessageCircle,
} from "react-icons/fi";

// Import Toastify components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import React Helmet
import { Helmet } from "react-helmet";
import FundWalletModal from "./reusableComponents/FundWalletModal";

// Ensure Modal is attached to the app root for accessibility
Modal.setAppElement("#root");

const Dashboard = () => {
  const [properties, setProperties] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [purchaseMessage, setPurchaseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [userId, setUserId] = useState("");
  const [isFundModalOpen, setIsFundModalOpen] = useState(false);
  const [actionModal, setActionModal] = useState({
    isOpen: false,
    type: "",
    house: null,
  });
  const [balance, setBalance] = useState(0.0);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  // Fetch data function wrapped in useCallback
  const fetchData = useCallback(
    async (userId) => {
      if (!userId) return;

      try {
        // Fetch properties
        const propertiesResponse = await axios.get(
          `${backendUrl}/chats/escrow/dashboard-houses/${userId}`
        );
        setProperties(propertiesResponse.data.houses);

        // Fetch wallet balance
        const walletResponse = await axios.get(
          `${backendUrl}/chats/wallet/${userId}`
        );
        setBalance(walletResponse.data.balance);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch dashboard data. Please try again.");
      }
    },
    [backendUrl]
  );

  // Fetch user data on mount
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedUserId = localStorage.getItem("userId");

    if (storedUserId) {
      setUserId(storedUserId);
      setEmail(storedEmail);
    } else {
      toast.error("User not authenticated. Please log in.");
      navigate("/login"); // Redirect to login if not authenticated
    }
  }, [navigate]);

  // Fetch data when userId is set
  useEffect(() => {
    if (userId) {
      fetchData(userId);
    }
  }, [userId, fetchData]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId && storedUserId !== userId) {
      setUserId(storedUserId);
    }
  }, [userId]);

  // Handle navigation to property details
  const handleViewClick = (houseId) => {
    navigate(`/propertyDetails2/${houseId}`);
  };

  // Handle navigation to chat
  const handleChatClick = (posterId) => {
    navigate(`/chat/${userId}/${posterId}`);
  };

  // Handle action buttons (purchase, cancel, confirm, request_confirmation)
  const handleActionClick = (type, house) => {
    setActionModal({ isOpen: true, type, house });
  };

  // Confirm action in modal
  const handleActionConfirm = async () => {
    const { type, house } = actionModal;

    if (!house) {
      toast.error("Invalid house data.");
      return;
    }

    if (type === "purchase") {
      try {
        const response = await axios.post(`${backendUrl}/chats/buy-house`, {
          userId,
          houseId: house.house_id,
        });

        if (response.data.success) {
          setPurchaseMessage("House successfully purchased");
          setIsSuccess(true);
          setBalance(response.data.balance);

          // Update properties status
          setProperties((prev) =>
            prev.map((prop) =>
              prop.house_id === house.house_id ? { ...prop, status: 1 } : prop
            )
          );

          await fetchData(userId);
        } else {
          setPurchaseMessage("Insufficient balance");
          setIsSuccess(false);
        }

        setIsPurchaseModalOpen(true); // Show the result modal
      } catch (error) {
        setPurchaseMessage("Error occurred during purchase");
        setIsSuccess(false);
        setIsPurchaseModalOpen(true); // Show error modal
      }
    } else if (type === "cancel") {
      try {
        const cancelData = {
          houseId: house.house_id,
        };

        if (house.role === "buyer") {
          cancelData.userId = userId;
        } else if (house.role === "seller") {
          cancelData.buyerId = house.buyer_id;
        }

        const response = await axios.post(
          `${backendUrl}/chats/escrow/cancel`,
          cancelData
        );

        if (response.status === 200) {
          toast.success(
            `Transaction for ${house.house_name} has been cancelled.`
          );
          // Update properties status
          setProperties((prev) =>
            prev.map((prop) =>
              prop.house_id === house.house_id && prop.role === house.role
                ? { ...prop, status: 3 }
                : prop
            )
          );

          await fetchData(userId);
        }
      } catch (error) {
        console.error("Error cancelling transaction:", error);
        toast.error(
          error.response?.data?.message ||
            "Cancellation failed. Please try again."
        );
      }
    } else if (type === "confirm") {
      try {
        const response = await axios.post(
          `${backendUrl}/chats/escrow/confirm`,
          {
            userId,
            houseId: house.house_id,
          }
        );

        if (response.status === 200) {
          toast.success(`House ${house.house_name} has been confirmed.`);
          // Update properties status
          setProperties((prev) =>
            prev.map((prop) =>
              prop.house_id === house.house_id ? { ...prop, status: 2 } : prop
            )
          );

          await fetchData(userId);
        }
      } catch (error) {
        console.error("Error confirming house:", error);
        toast.error(
          error.response?.data?.message ||
            "Confirmation failed. Please try again."
        );
      }
    } else if (type === "request_confirmation") {
      try {
        const requestData = {
          houseId: house.house_id,
          buyerId: house.buyer_id,
        };

        const response = await axios.post(
          `${backendUrl}/chats/escrow/request-confirmation`,
          requestData
        );

        if (response.status === 200) {
          toast.success(`Confirmation requested for ${house.house_name}.`);
          // Update properties status if needed
          setProperties((prev) =>
            prev.map((prop) =>
              prop.house_id === house.house_id && prop.role === house.role
                ? { ...prop, status: 10 }
                : prop
            )
          );

          await fetchData(userId);
        }
      } catch (error) {
        console.error("Error requesting confirmation:", error);
        toast.error(
          error.response?.data?.message || "Request failed. Please try again."
        );
      }
    }

    setActionModal({ isOpen: false, type: "", house: null });
  };

  // Close action modal without confirming
  const handleActionClose = () => {
    setActionModal({ isOpen: false, type: "", house: null });
  };

  // Convert status codes to readable text
  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Bought but Awaiting Confirmation";
      case 2:
        return "Bought and Confirmed";
      case 3:
        return "Cancelled";
      case 10:
        return "Confirmation Requested";
      default:
        return "Unknown";
    }
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredProperties = properties.filter(
    (property) =>
      property.house_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.tag.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentProperties = filteredProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredProperties.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>HousemeNG | Dashboard</title>
      </Helmet>
      <div className="font-halant">
        <ToastContainer />
        <main className="container mx-auto px-6 py-8 mt-24">
          <h2 className="text-3xl font-semibold mb-6 text-gray-800 dark:text-white">
            Dashboard
          </h2>

          <div className="flex flex-col md:flex-row justify-between items-center mb-8 space-y-4 md:space-y-0">
            {/* Search Input */}
            <div className="relative w-full md:w-1/3">
              <FiSearch className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                placeholder="Search by Name, Location, or Tag"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-3 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>

            {/* Wallet Section */}
            <div className="w-full md:w-1/3 flex flex-col items-end">
              <div className="mb-2 text-right">
                <p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                  Balance
                </p>
                <p className="text-2xl font-bold text-green-600 dark:text-green-400">
                  ₦
                  {balance !== undefined && balance !== null
                    ? balance.toFixed(2)
                    : "0.00"}
                </p>
              </div>
              <button
                onClick={() => setIsFundModalOpen(true)}
                className="w-full md:w-auto bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md font-semibold flex items-center justify-center space-x-2"
              >
                Fund Wallet
              </button>
            </div>
          </div>

          {/* Properties Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-100 dark:bg-gray-700 text-left">
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    #
                  </th>
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    Name
                  </th>
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    Location
                  </th>
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    Tag
                  </th>
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    Price
                  </th>
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    Agent
                  </th>
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    Status
                  </th>
                  <th className="px-6 py-3 text-sm font-bold text-gray-700 dark:text-gray-300">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-600 dark:text-gray-300">
                {currentProperties.length > 0 ? (
                  currentProperties.map((property, index) => (
                    <tr
                      key={`${property.house_id}-${property.role}`}
                      className="border-t border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <td
                        data-label="#"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td
                        data-label="Name"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        {property.house_name}
                      </td>
                      <td
                        data-label="Location"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        {property.location}
                      </td>
                      <td
                        data-label="Tag"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        {property.tag}
                      </td>
                      <td
                        data-label="Price"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        ₦{parseFloat(property.price).toLocaleString()}
                      </td>
                      <td
                        data-label="Agent"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        {property.role === "buyer" ? (
                          property.agent_name
                        ) : (
                          <>
                            {property.buyer_name}
                            <br />
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              (buyer)
                            </span>
                          </>
                        )}
                      </td>
                      <td
                        data-label="Status"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            property.status === 0
                              ? "bg-yellow-100 text-yellow-800"
                              : property.status === 1
                              ? "bg-blue-100 text-blue-800"
                              : property.status === 2
                              ? "bg-green-100 text-green-800"
                              : property.status === 3
                              ? "bg-red-100 text-red-800"
                              : property.status === 10
                              ? "bg-purple-100 text-purple-800"
                              : "bg-gray-100 text-gray-800"
                          }`}
                        >
                          {getStatusText(property.status)}
                        </span>
                      </td>
                      <td
                        data-label="Actions"
                        className="px-6 py-4 whitespace-nowrap"
                      >
                        {/* Actions based on role */}
                        {property.role === "buyer" ? (
                          // Actions for buyer
                          <div className="flex space-x-2">
                            {/* Chat Button */}
                            <button
                              onClick={() => handleChatClick(property.agent_id)}
                              className="flex items-center text-blue-500 hover:text-blue-700"
                              title="Chat"
                              aria-label={`Chat with ${property.agent_name}`}
                            >
                              <FiMessageCircle className="mr-1" /> Chat
                            </button>
                            {/* Buy Button */}
                            {property.status === 0 && (
                              <button
                                onClick={() =>
                                  handleActionClick("purchase", property)
                                }
                                className="flex items-center text-green-500 hover:text-green-700"
                                title="Buy Property"
                                aria-label={`Buy ${property.house_name}`}
                              >
                                <FiCheckCircle className="mr-1" /> Buy
                              </button>
                            )}
                            {/* Cancel Button */}
                            {(property.status === 0 ||
                              property.status === 1) && (
                              <button
                                onClick={() =>
                                  handleActionClick("cancel", property)
                                }
                                className="flex items-center text-red-500 hover:text-red-700"
                                title="Cancel Transaction"
                                aria-label={`Cancel transaction for ${property.house_name}`}
                              >
                                <FiXCircle className="mr-1" /> Cancel
                              </button>
                            )}
                            {/* Confirm Button */}
                            {(property.status === 1 ||
                              property.status === 10) && (
                              <button
                                onClick={() =>
                                  handleActionClick("confirm", property)
                                }
                                className="flex items-center bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-600"
                                title="Confirm Purchase"
                                aria-label={`Confirm purchase for ${property.house_name}`}
                              >
                                <FiCheckCircle className="mr-1" /> Confirm
                              </button>
                            )}
                          </div>
                        ) : (
                          // Actions for seller
                          <div className="flex space-x-2">
                            {/* Continue Chat Button */}
                            <button
                              onClick={() => handleChatClick(property.buyer_id)}
                              className="flex items-center text-blue-500 hover:text-blue-700"
                              title="Continue Chat"
                              aria-label={`Continue chat with ${property.buyer_name}`}
                            >
                              <FiMessageCircle className="mr-1" /> Chat
                            </button>
                            {/* Cancel Button */}
                            {(property.status === 0 ||
                              property.status === 1) && (
                              <button
                                onClick={() =>
                                  handleActionClick("cancel", property)
                                }
                                className="flex items-center text-red-500 hover:text-red-700"
                                title="Cancel Transaction"
                                aria-label={`Cancel transaction for ${property.house_name}`}
                              >
                                <FiXCircle className="mr-1" /> Cancel
                              </button>
                            )}
                            {/* Request Confirmation Button */}
                            {property.status === 1 && (
                              <button
                                onClick={() =>
                                  handleActionClick(
                                    "request_confirmation",
                                    property
                                  )
                                }
                                className="flex items-center bg-purple-500 text-white px-2 py-1 rounded-md hover:bg-purple-600"
                                title="Request Confirmation"
                                aria-label={`Request confirmation for ${property.house_name}`}
                              >
                                <FiEdit className="mr-1" /> Request Confirmation
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="py-4 text-center text-gray-600 dark:text-gray-300"
                    >
                      No properties found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md font-semibold ${
                currentPage === 1
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600 text-white"
              }`}
            >
              Previous
            </button>
            <span className="text-lg font-medium text-gray-800 dark:text-gray-200">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md font-semibold ${
                currentPage === totalPages
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600 text-white"
              }`}
            >
              Next
            </button>
          </div>
          
          {/* Reusable FundWalletModal Component */}
          <FundWalletModal
            isOpen={isFundModalOpen}
            onClose={() => setIsFundModalOpen(false)}
          />

          {/* Purchase Result Modal */}
          <Modal
            isOpen={isPurchaseModalOpen}
            onRequestClose={() => setIsPurchaseModalOpen(false)}
            contentLabel="Purchase Result"
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md mx-auto p-6 relative"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          >
            <button
              onClick={() => setIsPurchaseModalOpen(false)}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none"
              aria-label="Close Purchase Result Modal"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Purchase Status
            </h2>
            <div className="flex flex-col items-center">
              {isSuccess ? (
                <>
                  <FiCheckCircle className="text-green-500 w-12 h-12 mb-4" />
                  <p className="text-lg text-gray-700 dark:text-gray-300">
                    {purchaseMessage}
                  </p>
                </>
              ) : (
                <>
                  <FiXCircle className="text-red-500 w-12 h-12 mb-4" />
                  <p className="text-lg text-gray-700 dark:text-gray-300">
                    {purchaseMessage}
                  </p>
                  <button
                    onClick={() => setIsFundModalOpen(true)}
                    className="w-full md:w-auto bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md font-semibold flex items-center justify-center space-x-2"
                  >
                    Fund Wallet
                  </button>
                </>
              )}
            </div>
          </Modal>

          {/* Action Confirmation Modal */}
          <Modal
            isOpen={actionModal.isOpen}
            onRequestClose={handleActionClose}
            contentLabel="Action Confirmation"
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md mx-auto p-6 relative"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          >
            <button
              onClick={handleActionClose}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none"
              aria-label="Close Action Confirmation Modal"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              {actionModal.type === "purchase" && "Confirm Purchase"}
              {actionModal.type === "cancel" && "Cancel Transaction"}
              {actionModal.type === "confirm" && "Confirm House Condition"}
              {actionModal.type === "request_confirmation" &&
                "Request Confirmation"}
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
              {actionModal.type === "purchase" && (
                <>
                  Are you sure you want to purchase{" "}
                  <strong>{actionModal.house?.house_name}</strong> for ₦
                  {parseFloat(actionModal.house?.price).toLocaleString()}?
                </>
              )}
              {actionModal.type === "cancel" && (
                <>
                  Are you sure you want to cancel the transaction for{" "}
                  <strong>{actionModal.house?.house_name}</strong>?
                </>
              )}
              {actionModal.type === "confirm" && (
                <>
                  Are you sure the house{" "}
                  <strong>{actionModal.house?.house_name}</strong> is in the
                  correct condition?
                </>
              )}
              {actionModal.type === "request_confirmation" && (
                <>
                  Are you sure you want to request confirmation from the buyer
                  for <strong>{actionModal.house?.house_name}</strong>?
                </>
              )}
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleActionConfirm}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md font-semibold flex items-center space-x-2"
              >
                <FiCheckCircle /> Yes
              </button>
              <button
                onClick={handleActionClose}
                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md font-semibold flex items-center space-x-2"
              >
                <FiXCircle /> No
              </button>
            </div>
          </Modal>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
