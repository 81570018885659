// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/png/housemelogo_4.png";
import twitterIcon from "../../assets/socials/socialsIcons/png/X_Twitter.png";
import whatsappIcon from "../../assets/socials/socialsIcons/png/Whatsapp.png";
import facebookIcon from "../../assets/socials/socialsIcons/png/Facebook.png";
import linkedinIcon from "../../assets/socials/socialsIcons/png/Linkedin.png";
import instagramIcon from "../../assets/socials/socialsIcons/png/Instagram.png";

// Importing React Icons
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-200 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Upper Footer Section */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* About Us */}
          <div>
            <Link to="/" aria-label="Houseme.ng Home">
              <img src={logo} alt="Houseme.ng Logo" className="h-10 mb-4" />
            </Link>
            <p className="text-sm mb-4">
              Houseme.ng is your trusted partner in finding the perfect
              property. We connect buyers and sellers seamlessly, ensuring a
              hassle-free experience.
            </p>
            <div className="flex space-x-4">
              <a
                href="https://twitter.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
                className="hover:opacity-80 transition-opacity duration-200"
              >
                <img src={twitterIcon} alt="Twitter Icon" className="h-6 w-6" />
              </a>
              <a
                href="https://wa.me/yourwhatsappnumber"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="WhatsApp"
                className="hover:opacity-80 transition-opacity duration-200"
              >
                <img
                  src={whatsappIcon}
                  alt="WhatsApp Icon"
                  className="h-6 w-6"
                />
              </a>
              <a
                href="https://facebook.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                className="hover:opacity-80 transition-opacity duration-200"
              >
                <img
                  src={facebookIcon}
                  alt="Facebook Icon"
                  className="h-6 w-6"
                />
              </a>
              <a
                href="https://linkedin.com/in/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="hover:opacity-80 transition-opacity duration-200"
              >
                <img
                  src={linkedinIcon}
                  alt="LinkedIn Icon"
                  className="h-6 w-6"
                />
              </a>
              <a
                href="https://instagram.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                className="hover:opacity-80 transition-opacity duration-200"
              >
                <img
                  src={instagramIcon}
                  alt="Instagram Icon"
                  className="h-6 w-6"
                />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/faqs"
                  className="hover:text-white transition-colors duration-200 text-sm"
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  to="/support"
                  className="hover:text-white transition-colors duration-200 text-sm"
                >
                  Support
                </Link>
              </li>
              <li>
                <Link
                  to="/terms"
                  className="hover:text-white transition-colors duration-200 text-sm"
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="hover:text-white transition-colors duration-200 text-sm"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-4">
              <li className="flex items-center">
                <FaMapMarkerAlt className="text-green-500 mr-2" />
                <span className="text-sm">
                  1234 Real Estate Ave, Suite 100, The Milkyway
                </span>
              </li>
              <li className="flex items-center">
                <FaPhone className="text-green-500 mr-2" />
                <a
                  href="tel:+1234567890"
                  className="hover:text-white transition-colors duration-200 text-sm"
                >
                  +1 (234) 567-890
                </a>
              </li>
              <li className="flex items-center">
                <FaEnvelope className="text-green-500 mr-2" />
                <a
                  href="mailto:realhouseme.ng@gmail.com"
                  className="hover:text-white transition-colors duration-200 text-sm"
                >
                  realhouseme.ng@gmail.com
                </a>
              </li>
            </ul>
          </div>

          {/* Newsletter Subscription */}
          <div>
            <h3 className="text-lg font-semibold mb-4">
              Subscribe to Our Newsletter
            </h3>
            <form className="flex flex-col">
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded-md mb-4 text-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md transition-colors duration-200 text-sm"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Divider */}
        <div className="mt-12 border-t border-gray-700"></div>

        {/* Lower Footer Section */}
        <div className="mt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm">
            © {new Date().getFullYear()} Houseme.ng. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
