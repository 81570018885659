import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FiCamera, FiUser, FiHome, FiCheckCircle, FiX } from "react-icons/fi";
import { useAuth } from "./AuthContext";

const Profile = () => {
  const navigate = useNavigate();
  const { updateProfileImage } = useAuth();

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phone: "",
    userType: "",
    country: "Nigeria",
    residentialAddress: "",
    officeAddress: "",
    stateOfResidence: "",
  });

  const [profileImage, setProfileImage] = useState(null);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [userNameError, setUserNameError] = useState("");
  const [isUserNameUnique, setIsUserNameUnique] = useState(null);
  const debounceTimer = useRef(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem("userId");
    if (userIdFromStorage) {
      setUserId(userIdFromStorage);
    } else {
      alert("User not authenticated. Please log in.");
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      axios
        .get(`${backendUrl}/users/users/${userId}`)
        .then((response) => {
          const userData = response.data;
          setFormData({
            title: userData.title || "",
            firstName: userData.firstname || "",
            lastName: userData.lastname || "",
            userName: userData.username || "",
            email: userData.email || "",
            phone: userData.phone || "",
            userType: userData.user_type || "",
            country: userData.country || "Nigeria",
            residentialAddress: userData.residentialAddress || "",
            officeAddress: userData.officeAddress || "",
            stateOfResidence: userData.stateOfResidence || "",
          });
          if (userData.avatar) {
            setProfileImage(`${backendUrl2}/${userData.avatar}`);
            updateProfileImage(`${backendUrl2}/${userData.avatar}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setError("Failed to fetch user data.");
        });
    }
  }, [userId, backendUrl, backendUrl2, updateProfileImage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
    }
  };

  const checkUserName = useCallback(
    async (username) => {
      if (!username) {
        setUserNameError("");
        setIsUserNameUnique(null);
        return;
      }

      try {
        const response = await axios.get(`${backendUrl}/auth/check-username`, {
          params: { username, userId },
        });

        if (response.data.isUnique) {
          setUserNameError("");
          setIsUserNameUnique(true);
        } else {
          setUserNameError("Username is already taken.");
          setIsUserNameUnique(false);
        }
      } catch (err) {
        console.error("Error checking username:", err);
        setUserNameError("Unable to verify username. Please try again.");
        setIsUserNameUnique(false);
      }
    },
    [backendUrl, userId]
  );

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      checkUserName(formData.userName);
    }, 500);

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [formData.userName, checkUserName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!isUserNameUnique) {
      setError("Please choose a unique username.");
      setIsSubmitting(false);
      return;
    }

    const updateData = new FormData();
    updateData.append("title", formData.title);
    updateData.append("firstName", formData.firstName);
    updateData.append("lastName", formData.lastName);
    updateData.append("userName", formData.userName);
    updateData.append("email", formData.email);
    updateData.append("phone", formData.phone);
    updateData.append("userType", formData.userType);
    updateData.append("country", formData.country);
    updateData.append("residentialAddress", formData.residentialAddress);
    updateData.append("officeAddress", formData.officeAddress);
    updateData.append("stateOfResidence", formData.stateOfResidence);

    if (profileImage && typeof profileImage !== "string") {
      updateData.append("profileImage", profileImage);
    }

    try {
      const response = await axios.put(
        `${backendUrl}/users/users/${userId}`,
        updateData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const avatar = response.data.avatar
        ? `${backendUrl2}/${response.data.avatar}`
        : localStorage.getItem("avatar");

      localStorage.setItem("avatar", avatar);
      localStorage.setItem(
        "firstName",
        response.data.firstName || formData.firstName
      );
      localStorage.setItem(
        "lastName",
        response.data.lastName || formData.lastName
      );
      localStorage.setItem(
        "userName",
        response.data.userName || formData.userName
      );

      setIsModalVisible(true);
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  return (
    <>
      <head>
        <title>HousemeNG | Profile</title>
      </head>
      <div className="font-halant container mx-auto px-6 py-8 mt-24">
        <div className="bg-white p-6">
          <h2 className="text-3xl font-semibold mb-6 text-gray-800">
            Profile Details
          </h2>

          {error && (
            <p className="text-red-500 text-center font-semibold mb-4">
              {error}
            </p>
          )}

          {/* Profile Image Section */}
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:items-center justify-between">
              <div className="flex items-center">
                <div className="relative w-24 h-24">
                  {profileImage ? (
                    typeof profileImage === "string" ? (
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="w-full h-full object-cover rounded-full"
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(profileImage)}
                        alt="Profile"
                        className="w-full h-full object-cover rounded-full"
                      />
                    )
                  ) : (
                    <div className="w-full h-full bg-gray-200 rounded-full flex items-center justify-center">
                      <FiCamera className="text-gray-500 text-4xl" />
                    </div>
                  )}
                  <label
                    htmlFor="profileImage"
                    className="absolute bottom-0 right-0 bg-green-500 text-white rounded-full p-2 cursor-pointer hover:bg-green-600"
                  >
                    <FiCamera />
                    <input
                      id="profileImage"
                      name="profileImage"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </label>
                </div>
                <div className="ml-4">
                  <h3 className="text-xl font-semibold text-gray-700 flex items-center">
                    {formData.firstName} {formData.lastName}
                  </h3>
                  <p className="text-gray-600 mt-1">{formData.email}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Personal Information Section */}
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
              <FiUser className="mr-2" /> Personal Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Title */}
              <div>
                <label
                  htmlFor="title"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Title<span className="text-red-500">*</span>
                </label>
                <select
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select Title</option>
                  <option>Mr.</option>
                  <option>Ms.</option>
                  <option>Mrs.</option>
                </select>
              </div>

              {/* First Name */}
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  First Name<span className="text-red-500">*</span>
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>

              {/* Last Name */}
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Last Name<span className="text-red-500">*</span>
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>

              {/* Username */}
              <div>
                <label
                  htmlFor="userName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Username<span className="text-red-500">*</span>
                </label>
                <input
                  id="userName"
                  name="userName"
                  type="text"
                  value={formData.userName}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 ${
                    userNameError ? "border-red-500" : "focus:ring-green-500"
                  }`}
                  required
                />
                {userNameError && (
                  <p className="text-red-500 text-sm mt-1">{userNameError}</p>
                )}
                {isUserNameUnique && (
                  <p className="text-green-500 text-sm mt-1">
                    Username is available.
                  </p>
                )}
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Email Address<span className="text-red-500">*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
                  readOnly
                />
              </div>

              {/* Phone */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone Number<span className="text-red-500">*</span>
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  pattern="[+, 0-9]*"
                  inputMode="numeric"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* User Type */}
              <div>
                <label
                  htmlFor="userType"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  User Type<span className="text-red-500">*</span>
                </label>
                <select
                  id="userType"
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select User Type</option>
                  <option value="3">Buyer and Seller</option>
                  <option value="2">Buyer</option>
                  <option value="1">Seller</option>
                </select>
              </div>

              {/* Country */}
              <div>
                <label
                  htmlFor="country"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Country<span className="text-red-500">*</span>
                </label>
                <input
                  id="country"
                  name="country"
                  type="text"
                  value={formData.country}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>
            </div>
          </div>

          {/* Address Information Section */}
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
              <FiHome className="mr-2" /> Address Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* State of Residence */}
              <div>
                <label
                  htmlFor="stateOfResidence"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  State of Residence
                </label>
                <input
                  id="stateOfResidence"
                  name="stateOfResidence"
                  type="text"
                  value={formData.stateOfResidence}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Residential Address */}
              <div>
                <label
                  htmlFor="residentialAddress"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Residential Address
                </label>
                <input
                  id="residentialAddress"
                  name="residentialAddress"
                  type="text"
                  value={formData.residentialAddress}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Office Address */}
              <div>
                <label
                  htmlFor="officeAddress"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Office Address
                </label>
                <input
                  id="officeAddress"
                  name="officeAddress"
                  type="text"
                  value={formData.officeAddress}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              onClick={handleSubmit}
              className={`bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md font-semibold ${
                isSubmitting || !isUserNameUnique
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={isSubmitting || !isUserNameUnique}
            >
              {isSubmitting ? "Updating..." : "Update Profile"}
            </button>
          </div>
        </div>

        {/* Success Modal */}
        {isModalVisible && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
              <button
                onClick={handleCloseModal}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close Modal"
              >
                <FiX size={24} />
              </button>
              <div className="flex items-center justify-center mb-4">
                <FiCheckCircle className="w-16 h-16 text-green-500" />
              </div>
              <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                Profile Updated Successfully
              </h3>
              <p className="text-gray-600">
                Your profile information has been updated.
              </p>
              <div className="mt-6 flex justify-end">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md font-semibold"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
