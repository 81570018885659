// src/components/AddProperty.js

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import { FiTrash2 } from "react-icons/fi";

// Ensure Modal is attached to the app root for accessibility
Modal.setAppElement("#root");

const AddProperty = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [userId, setUserId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      alert("User not authenticated. Please log in.");
      window.location.href = "/login"; // Redirect to login if not authenticated
    }
  }, []);

  const [formData, setFormData] = useState({
    propertyTitle: "",
    propertyAddress: "",
    nearestLandmark: "", // New field
    aboutProperty: "",
    listerTitle: "",
    propertyLocation: "",
    state: "",
    price: "",
    tag: "",
    bedrooms: 0,
    bathrooms: 0,
    carParks: 0,
    dimensions: 0,
  });

  // Handle image selection using React Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedImages((prevImages) => [...prevImages, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  // Remove selected image
  const removeImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Handle form data change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Additional validation can be added here
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedImages.length === 0) {
      alert("Please upload at least one image.");
      return;
    }

    setIsSubmitting(true);

    // Prepare the data to be sent to the backend
    const data = new FormData();
    data.append("propertyTitle", formData.propertyTitle);
    data.append("propertyAddress", formData.propertyAddress);
    data.append("nearestLandmark", formData.nearestLandmark); // Append Nearest Landmark
    data.append("aboutProperty", formData.aboutProperty);
    data.append("listerTitle", formData.listerTitle);
    data.append("propertyLocation", formData.propertyLocation);
    data.append("state", formData.state);
    data.append("price", formData.price);
    data.append("tag", formData.tag);
    data.append("bedrooms", formData.bedrooms);
    data.append("bathrooms", formData.bathrooms);
    data.append("carParks", formData.carParks);
    data.append("dimensions", formData.dimensions);
    data.append("userId", userId);

    // Append selected images to form data
    selectedImages.forEach((image) => {
      data.append("images", image);
    });

    try {
      // Send data to the backend
      const response = await axios.post(`${backendUrl}/houses`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Property added successfully", response.data);
      // Open modal on success
      setModalVisible(true);
    } catch (error) {
      console.error("Error uploading property:", error);
      alert("Failed to add property. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <head>
        <title>HousemeNG | Add Properties</title>
      </head>
      <div className="font-halant container mx-auto px-6 py-8 mt-24">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">
          Add Property
        </h2>
        <p className="text-lg mb-6 text-gray-600">
          Fill in the details below to list a new property.
        </p>
        <div className="bg-white rounded-lg p-6">
          <form onSubmit={handleSubmit}>
            {/* Property Title */}
            <div className="mb-6">
              <label
                htmlFor="propertyTitle"
                className="block text-gray-700 font-semibold mb-2"
              >
                Property Title<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="propertyTitle"
                name="propertyTitle"
                value={formData.propertyTitle}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>

            {/* Property Address and Nearest Landmark */}
            <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Property Address */}
              <div>
                <label
                  htmlFor="propertyAddress"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Property Address<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="propertyAddress"
                  name="propertyAddress"
                  value={formData.propertyAddress}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>

              {/* Nearest Landmark */}
              <div>
                <label
                  htmlFor="nearestLandmark"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Nearest Landmark<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="nearestLandmark"
                  name="nearestLandmark"
                  value={formData.nearestLandmark}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>
            </div>

            {/* About Property */}
            <div className="mb-6">
              <label
                htmlFor="aboutProperty"
                className="block text-gray-700 font-semibold mb-2"
              >
                About The Property<span className="text-red-500">*</span>
              </label>
              <textarea
                id="aboutProperty"
                name="aboutProperty"
                value={formData.aboutProperty}
                onChange={handleInputChange}
                rows="5"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              ></textarea>
            </div>

            {/* Images */}
            <div className="mb-8">
              <label className="block text-gray-700 font-semibold mb-2">
                Images<span className="text-red-500">*</span>
              </label>
              <div
                {...getRootProps()}
                className="flex items-center justify-center border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:border-green-500 py-6"
              >
                <input
                  {...getInputProps()}
                  type="file"
                  id="newImages"
                  multiple
                  accept="image/*"
                />
                <p className="text-gray-500">
                  Drag & drop images here, or click to select files
                </p>
              </div>
              <div className="flex flex-wrap gap-4 mt-4">
                {selectedImages.map((image, index) => (
                  <div key={index} className="relative w-32 h-32">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Preview"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute top-1 right-1 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center focus:outline-none"
                      aria-label="Remove image"
                    >
                      <FiTrash2 size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Form Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Lister Title */}
              <div>
                <label
                  htmlFor="listerTitle"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Title of Lister<span className="text-red-500">*</span>
                </label>
                <select
                  id="listerTitle"
                  name="listerTitle"
                  value={formData.listerTitle}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select Title</option>
                  <option value="Landlord/Owner">Landlord/Owner</option>
                  <option value="Agent">Agent</option>
                </select>
              </div>

              {/* Property Location */}
              <div>
                <label
                  htmlFor="propertyLocation"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Property Location (Country)
                  <span className="text-red-500">*</span>
                </label>
                <select
                  id="propertyLocation"
                  name="propertyLocation"
                  value={formData.propertyLocation}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select Country</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {/* State */}
              <div>
                <label
                  htmlFor="state"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  State<span className="text-red-500">*</span>
                </label>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select State</option>
                  <option value="Lagos">Lagos</option>
                  <option value="Abuja">Abuja</option>
                  {/* Add more states as necessary */}
                </select>
              </div>

              {/* Price */}
              <div>
                <label
                  htmlFor="price"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Price (₦)<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>

              {/* Tag */}
              <div>
                <label
                  htmlFor="tag"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Tag<span className="text-red-500">*</span>
                </label>
                <select
                  id="tag"
                  name="tag"
                  value={formData.tag}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select Tag</option>
                  <option value="Sale">Sale</option>
                  <option value="Rent">Rent</option>
                  <option value="Shortlet">Shortlet</option>
                  <option value="Co-working">Co-working Space</option>
                  <option value="Warehouse">Warehouse</option>
                  <option value="EventCentre">Event Centre</option>
                  <option value="Office">Office</option>
                </select>
              </div>

              {/* Bedrooms */}
              <div>
                <label
                  htmlFor="bedrooms"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Bedrooms
                </label>
                <input
                  type="number"
                  id="bedrooms"
                  name="bedrooms"
                  value={formData.bedrooms}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Bathrooms */}
              <div>
                <label
                  htmlFor="bathrooms"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Bathrooms
                </label>
                <input
                  type="number"
                  id="bathrooms"
                  name="bathrooms"
                  value={formData.bathrooms}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Car Parks */}
              <div>
                <label
                  htmlFor="carParks"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Car Parks
                </label>
                <input
                  type="number"
                  id="carParks"
                  name="carParks"
                  value={formData.carParks}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Dimensions */}
              <div>
                <label
                  htmlFor="dimensions"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Dimensions (sqm)
                </label>
                <input
                  type="number"
                  id="dimensions"
                  name="dimensions"
                  value={formData.dimensions}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
            </div>

            {/* Submit Button */}
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className={`w-full md:w-auto bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-md font-semibold flex items-center justify-center ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Listing..." : "List Property"}
              </button>
            </div>
          </form>
        </div>

        {/* Success Modal */}
        <Modal
          isOpen={modalVisible}
          onRequestClose={() => {
            setModalVisible(false);
            window.location.href = "/manage-houses"; // Redirect after closing modal
          }}
          contentLabel="Property Added"
          className="bg-white rounded-lg shadow-lg max-w-md mx-auto p-6 relative"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <button
            onClick={() => {
              setModalVisible(false);
              window.location.href = "/manage-houses"; // Redirect after closing modal
            }}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close Modal"
          >
            &times;
          </button>
          <div className="text-center">
            <div className="flex items-center justify-center mb-4">
              <svg
                className="w-16 h-16 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m5 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-2 text-gray-800">
              Property Listed Successfully!
            </h2>
            <p className="text-gray-600">
              Your property has been added and is now visible to potential
              buyers.
            </p>
            <div className="mt-6">
              <button
                onClick={() => {
                  setModalVisible(false);
                  window.location.href = "/manage-houses";
                }}
                className="bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-md font-semibold"
              >
                Go to Manage Houses
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddProperty;
