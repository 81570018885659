// src/components/Settings.js

import React, { useState, useEffect } from "react";
import {
  FiCheckCircle,
  FiEdit,
  FiMail,
  FiLock,
  FiBell,
  FiTrash2,
  FiX,
} from "react-icons/fi";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Settings = () => {
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [showEditEmailModal, setShowEditEmailModal] = useState(false);
  const [backendUrl] = useState(process.env.REACT_APP_BACKEND_URL);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        try {
          const response = await axios.get(
            `${backendUrl}/users/users/${storedUserId}`
          );
          setEmail(response.data.email);
          setEmailVerified(response.data.emailVerified);
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast.error("Failed to fetch user data.");
        }
      }
    };

    fetchUserData();
  }, [backendUrl]);

  const handleVerifyEmail = async () => {
    try {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        await axios.post(
          `${backendUrl}/auth/${storedUserId}/send-verification-email`
        );
        toast.success(
          "A verification email has been sent to your email address."
        );
      }
    } catch (error) {
      console.error("Error sending verification email:", error);
      toast.error("Failed to send verification email. Please try again later.");
    }
  };

  const handleEmailEdit = () => {
    setShowEditEmailModal(true);
  };

  const handleEmailSave = async () => {
    try {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        await axios.put(`${backendUrl}/auth/${storedUserId}/update-email`, {
          newEmail,
        });
        setEmail(newEmail);
        setEmailVerified(false);
        toast.success(
          "Your email has been updated. A verification email has been sent to your new email address."
        );
        setShowEditEmailModal(false);
      }
    } catch (error) {
      console.error("Error updating email:", error);
      toast.error("Failed to update email. Please try again later.");
    }
  };

  const handleEmailCancel = () => {
    setNewEmail("");
    setShowEditEmailModal(false);
  };

  const handleInputChange = (event) => {
    setNewEmail(event.target.value);
  };

  const handlePasswordChange = () => {
    setShowChangePasswordModal(true);
  };

  const handlePasswordSave = async () => {
    try {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        await axios.put(`${backendUrl}/auth/${storedUserId}/update-password`, {
          oldPassword,
          newPassword,
        });
        toast.success("Your password has been updated successfully.");
        setShowChangePasswordModal(false);
        setOldPassword("");
        setNewPassword("");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error(
        "Failed to update password. Please ensure your old password is correct."
      );
    }
  };

  const handlePasswordCancel = () => {
    setOldPassword("");
    setNewPassword("");
    setShowChangePasswordModal(false);
  };

  const handleNotificationsToggle = () => {
    setNotificationsEnabled(!notificationsEnabled);
    toast.info(
      `Notifications ${notificationsEnabled ? "disabled" : "enabled"}.`
    );
  };

  const handleDeactivateAccount = () => {
    setShowDeactivateModal(true);
  };

  const confirmDeactivateAccount = () => {
    console.log("Deactivating account");
    toast.success("Your account has been deactivated.");
    setShowDeactivateModal(false);
  };

  const cancelDeactivateAccount = () => {
    setShowDeactivateModal(false);
  };

  return (
    <>
      <head>
        <title>HousemeNG | Settings</title>
      </head>
      <div className="font-halant container mx-auto px-6 py-8 mt-24">
        <ToastContainer />
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">
          Account Settings
        </h2>
        <div className="bg-white p-6">
          {/* Email Verification */}
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold text-gray-700 flex items-center">
                  <FiMail className="mr-2" /> Email Verification
                </h3>
                <p className="text-gray-600 mt-1">
                  Verify the email address associated with this account.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row items-start sm:items-center mt-4 md:mt-0 w-full md:w-auto">
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium mr-0 sm:mr-2 mb-2 sm:mb-0 ${
                    emailVerified
                      ? "bg-green-100 text-green-700"
                      : "bg-yellow-100 text-yellow-700"
                  }`}
                >
                  {email}
                  {emailVerified && <FiCheckCircle className="inline ml-1" />}
                </span>
                {!emailVerified && (
                  <button
                    onClick={handleVerifyEmail}
                    className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-md w-full sm:w-auto mb-2 sm:mb-0 sm:mr-2"
                  >
                    Verify Email
                  </button>
                )}
                <button
                  onClick={handleEmailEdit}
                  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md flex items-center w-full sm:w-auto"
                >
                  <FiEdit className="mr-1" /> Edit
                </button>
              </div>
            </div>
          </div>

          {/* Change Password */}
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold text-gray-700 flex items-center">
                  <FiLock className="mr-2" /> Change Password
                </h3>
                <p className="text-gray-600 mt-1">
                  Change the password linked with this account.
                </p>
              </div>
              <button
                onClick={handlePasswordChange}
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md flex items-center mt-4 md:mt-0 w-full md:w-auto"
              >
                <FiEdit className="mr-1" /> Change Password
              </button>
            </div>
          </div>

          {/* Notifications */}
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold text-gray-700 flex items-center">
                  <FiBell className="mr-2" /> Notifications
                </h3>
                <p className="text-gray-600 mt-1">
                  Notifications will be sent to your registered email and phone
                  number.
                </p>
              </div>
              <button
                onClick={handleNotificationsToggle}
                className={`${
                  notificationsEnabled
                    ? "bg-green-500 hover:bg-green-600"
                    : "bg-gray-300 hover:bg-gray-400"
                } text-white px-4 py-2 rounded-md flex items-center mt-4 md:mt-0 w-full md:w-auto`}
              >
                {notificationsEnabled ? "Enabled" : "Disabled"}
              </button>
            </div>
          </div>

          {/* Deactivate Account */}
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold text-gray-700 flex items-center">
                  <FiTrash2 className="mr-2" /> Temporarily Deactivate Account
                </h3>
                <p className="text-gray-600 mt-1">
                  This will deactivate your account until you log back in.
                </p>
              </div>
              <button
                onClick={handleDeactivateAccount}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md flex items-center mt-4 md:mt-0 w-full md:w-auto"
              >
                Deactivate Account
              </button>
            </div>
          </div>
        </div>

        {/* Edit Email Modal */}
        {showEditEmailModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
              <button
                onClick={handleEmailCancel}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close Modal"
              >
                <FiX size={24} />
              </button>
              <h3 className="text-2xl font-semibold mb-4">Edit Email</h3>
              <input
                type="email"
                value={newEmail}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 mb-4"
                placeholder="New Email Address"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleEmailSave}
                  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md mr-2"
                >
                  Save
                </button>
                <button
                  onClick={handleEmailCancel}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Change Password Modal */}
        {showChangePasswordModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
              <button
                onClick={handlePasswordCancel}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close Modal"
              >
                <FiX size={24} />
              </button>
              <h3 className="text-2xl font-semibold mb-4">Change Password</h3>
              <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 mb-4"
                placeholder="Old Password"
              />
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 mb-4"
                placeholder="New Password"
              />
              <div className="flex justify-end">
                <button
                  onClick={handlePasswordSave}
                  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md mr-2"
                >
                  Save
                </button>
                <button
                  onClick={handlePasswordCancel}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Deactivate Account Confirmation Modal */}
        {showDeactivateModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
              <button
                onClick={cancelDeactivateAccount}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close Modal"
              >
                <FiX size={24} />
              </button>
              <h3 className="text-2xl font-semibold mb-4">
                Confirm Deactivation
              </h3>
              <p className="mb-6">
                Are you sure you want to deactivate your account? This action is
                irreversible.
              </p>
              <div className="flex justify-end">
                <button
                  onClick={confirmDeactivateAccount}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md mr-2"
                >
                  Deactivate
                </button>
                <button
                  onClick={cancelDeactivateAccount}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Settings;
