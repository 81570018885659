import "./App.css";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "leaflet/dist/leaflet.css";
import SignUp from "./components/SignUp";
import Home from "./components/Home";
import Landing from "./components/Landing";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import NewPassword from "./components/NewPassword";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import Buy from "./components/Buy";
import Rent from "./components/Rent";
import Sell from "./components/seller/Sell";
import AddProperty from "./components/seller/AddProperties";
import PersonalInformation from "./components/seller/kyc/PersonalInformation";
import DocumentsUploads from "./components/seller/kyc/DocumentsUpload";
import Dashboard from "./components/Dashboard";
import Shortlet from "./components/Shortlet";
import Support from "./components/Support";
import Layout from "./components/Layout";
import PropertyDetails from "./components/PropertyDetails";
import Favourites from "./components/Favourites";
import AdminVerificationPage from "./components/AdminKyc";
import ManageHousesPage from "./components/ManagePage";
import EditHouse from "./components/EditProperties";
import PropertyDetails2 from "./components/PropertyDetails2";
import Chat from "./components/Chat";
import ProtectedRoute from "./components/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import EmailVerified from "./components/EmailVerified";
import Notifications from "./components/Notifications";
import Loading from "./components/Loading";
import Faqs from "./components/Faqs";
import Coworking from "./components/CoWorking";
import Warehouse from "./components/Warehouse";
import EventCentre from "./components/EventCentre";
import Office from "./components/Office";
import UserDetails from "./components/UserDetails";

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    // Show the loading screen on route change
    const loadingScreen = document.getElementById("loading-screen");
    if (loadingScreen) {
      loadingScreen.style.display = "flex";
    }

    // Hide the loading screen after a short delay
    const timer = setTimeout(() => {
      if (loadingScreen) {
        loadingScreen.style.display = "none";
      }
    }, 1000); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      <Loading />
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/newPassword" element={<NewPassword />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route path="/buy" element={<Buy />} />
          <Route path="/propertyDetails/:id" element={<PropertyDetails />} />
          <Route path="/propertyDetails2/:id" element={<PropertyDetails2 />} />
          <Route
            path="/chat/:user1/:user2"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route path="/notifications" element={<Notifications />} />

          <Route
            path="/edit-house/:id"
            element={
              <ProtectedRoute>
                <EditHouse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/kycadmin"
            element={
              <ProtectedRoute>
                <AdminVerificationPage />
              </ProtectedRoute>
            }
          />
          <Route path="/rent" element={<Rent />} />
          <Route
            path="/rent"
            element={
              <ProtectedRoute>
                <ManageHousesPage />
              </ProtectedRoute>
            }
          />
          <Route path="/shortlet" element={<Shortlet />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/office" element={<Office />} />
          <Route path="/event-centre" element={<EventCentre />} />
          <Route path="/warehouse" element={<Warehouse />} />
          <Route path="/co-working-space" element={<Coworking />} />
          <Route path="/email-verified" element={<EmailVerified />} />
          <Route path="/user-details/:userId" element={<UserDetails />} />
          <Route
            path="/addProperties"
            element={
              <ProtectedRoute>
                <AddProperty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/personalInfo"
            element={
              <ProtectedRoute>
                <PersonalInformation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/docUpload"
            element={
              <ProtectedRoute>
                <DocumentsUploads />
              </ProtectedRoute>
            }
          />
          <Route
            path="/userDashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/support" element={<Support />} />
          <Route
            path="/favourites"
            element={
              <ProtectedRoute>
                <Favourites />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-houses"
            element={
              <ProtectedRoute>
                <ManageHousesPage />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
