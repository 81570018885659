import { useAuth } from "../AuthContext";
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/logo/png/housemelogo_4.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { FiBell } from "react-icons/fi";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  AdminPanelSettingsOutlined,
  ChatOutlined,
  DashboardOutlined,
  FavoriteBorderOutlined,
  ManageAccountsOutlined,
  WalletOutlined,
} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { toast } from "react-toastify";
import FundWalletModal from "./FundWalletModal";

function Navbar() {
  const { isLoggedIn, logout, profileImage } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isFundModalOpen, setIsFundModalOpen] = useState(false);
  const [balance, setBalance] = useState(0.0);
  const [email, setEmail] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const hamburgerRef = useRef(null);
  const profileRef = useRef(null);
  const moreMenuRef = useRef(null);
  const location = useLocation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [verificationStatus, setVerificationStatus] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleHamburger = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  const noIconRoutes = ["/signup", "/login", "/forgotPassword", "/newPassword"];

  const isChatPage = location.pathname.startsWith("/chat");

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  // Fetch data function wrapped in useCallback
  const fetchData = useCallback(
    async (userId) => {
      if (!userId) return;
      try {
        // Fetch wallet balance
        const walletResponse = await axios.get(
          `${backendUrl}/chats/wallet/${userId}`
        );
        setBalance(walletResponse.data.balance);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch dashboard data. Please try again.");
      }
    },
    [backendUrl]
  );

  // Fetch data when userId is set
  useEffect(() => {
    if (userId) {
      fetchData(userId);
    }
  }, [userId, fetchData]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
      fetchData(storedUserId);
    }
  }, [backendUrl]);

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/users/admincheck/${userId}`
        );
        const { verification } = response.data;
        setVerificationStatus(verification);
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    };

    if (userId) {
      fetchVerificationStatus();
    }
  }, [backendUrl, userId]);

  const handleScroll = useCallback(() => {
    if (!isChatPage) {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos;
      setVisible(isVisible);
      setPrevScrollPos(currentScrollPos);
    }
  }, [prevScrollPos, isChatPage]);

  useEffect(() => {
    if (!isChatPage) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, isChatPage]);

  useEffect(() => {
    setIsHamburgerOpen(false);
    setIsMenuOpen(false);
    setIsNotificationsOpen(false);
    setIsMoreMenuOpen(false);
  }, [location]);

  // Fetch notifications
  useEffect(() => {
    if (isLoggedIn) {
      fetchNotifications();
    }
  }, [isLoggedIn]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${backendUrl}/notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setNotifications(response.data.notifications);
      const unread = response.data.notifications.filter((n) => !n.read).length;
      setUnreadCount(unread);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markNotificationsAsRead = async () => {
    try {
      await axios.post(
        `${backendUrl}/notifications/mark-as-read`,
        {
          userId: localStorage.getItem("userId"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUnreadCount(0);
      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, read: true }))
      );
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    if (!isNotificationsOpen) {
      markNotificationsAsRead();
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isHamburgerOpen &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsHamburgerOpen(false);
      }
      if (
        isMenuOpen &&
        profileRef.current &&
        !profileRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
      if (
        isMoreMenuOpen &&
        moreMenuRef.current &&
        !moreMenuRef.current.contains(event.target)
      ) {
        setIsMoreMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isHamburgerOpen, isMenuOpen, isMoreMenuOpen]);

  return (
    <nav
      className={`flex items-center justify-between bg-white py-5 px-3 shadow-md top-0 fixed w-full z-[2001] transition-transform duration-300 ${
        visible || isChatPage
          ? "transform translate-y-0"
          : "transform -translate-y-full"
      }`}
    >
      {/* Hamburger menu (only for logged-in users) */}
      <div className="relative md:hidden flex">
        {!noIconRoutes.includes(location.pathname) && (
          <div className="items-center space-x-4">
            <button
              className="rounded-full h-10 w-10 flex items-center justify-center"
              onClick={toggleHamburger}
            >
              {isHamburgerOpen ? (
                <CloseRoundedIcon style={{ fontSize: "25px" }} />
              ) : (
                <MenuRoundedIcon style={{ fontSize: "25px" }} />
              )}{" "}
            </button>
          </div>
        )}

        {/* Hamburger menu dropdown */}
        {isHamburgerOpen && (
          <div
            ref={hamburgerRef}
            className="absolute -left-3 w-[100vw] bg-white rounded-2xl shadow-lg shadow-black z-10 mt-[152%]"
          >
            <div>
              <div className="items-center justify-center">
                {/* Menu Links */}
                <Link to="/home" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2 ${
                      location.pathname === "/home"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Home
                  </h2>
                </Link>
                <Link to="/buy" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2  rounded-sm ${
                      location.pathname === "/buy"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Buy
                  </h2>
                </Link>
                <Link to="/rent" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2  rounded-sm ${
                      location.pathname === "/rent"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Rent
                  </h2>
                </Link>
                <Link to="/shortlet" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2  rounded-sm ${
                      location.pathname === "/shortlet"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Shortlet
                  </h2>
                </Link>
                {/* New Links */}
                <Link to="/co-working-space" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2  rounded-sm ${
                      location.pathname === "/co-working-space"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Co-working Space
                  </h2>
                </Link>
                <Link to="/warehouse" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2  rounded-sm ${
                      location.pathname === "/warehouse"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Warehouse
                  </h2>
                </Link>
                <Link to="/event-centre" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2  rounded-sm ${
                      location.pathname === "/event-centre"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Event Center
                  </h2>
                </Link>
                <Link to="/office" className="hover:">
                  <h2
                    className={`font-halant font-semibold text-base text-gray-700 pl-7 hover:text-white flex justify-left p-2  rounded-sm ${
                      location.pathname === "/office"
                        ? "bg-green-700 text-white"
                        : "hover:bg-green-500"
                    }`}
                  >
                    Office
                  </h2>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Logo */}
      {!noIconRoutes.includes(location.pathname) && (
        <div className="flex items-center">
          <Link to="/home">
            <img src={logo} alt="house me logo" className="h-7" />
          </Link>
        </div>
      )}
      {noIconRoutes.includes(location.pathname) && (
        <div className="flex items-center">
          <div className="w-[100vw] flex justify-center">
            <Link to="/home">
              <img src={logo} alt="house me logo" className="h-7" />
            </Link>
          </div>
        </div>
      )}

      {/* Navigation Links */}
      {!noIconRoutes.includes(location.pathname) && (
        <div className="md:flex space-x-6 hidden text-sm items-center">
          <Link
            to="/home"
            className={`text-black font-halant font-semibold py-2 px-5 border-2 border-transparent hover:rounded-3xl hover:text-white hover:border-2 ${
              location.pathname === "/home"
                ? "bg-green-700 rounded-3xl text-white"
                : "hover:bg-green-500"
            }`}
          >
            Home
          </Link>
          <Link
            to="/buy"
            className={`text-black font-halant font-semibold py-2 px-5 border-2 border-transparent hover:rounded-3xl hover:text-white hover:border-2 ${
              location.pathname === "/buy"
                ? "bg-green-700 rounded-3xl text-white"
                : "hover:bg-green-500"
            }`}
          >
            Buy
          </Link>
          <Link
            to="/rent"
            className={`text-black font-halant font-semibold py-2 px-5 border-2 border-transparent hover:rounded-3xl hover:text-white hover:border-2 ${
              location.pathname === "/rent"
                ? "bg-green-700 rounded-3xl text-white"
                : "hover:bg-green-500"
            }`}
          >
            Rent
          </Link>
          {/* Shortlet Link - visible on xl screens and above */}
          <Link
            to="/shortlet"
            className={`hidden lg:inline-block text-black font-halant font-semibold py-2 px-5 border-2 border-transparent hover:rounded-3xl hover:text-white hover:border-2 ${
              location.pathname === "/shortlet"
                ? "bg-green-700 rounded-3xl text-white"
                : "hover:bg-green-500"
            }`}
          >
            Shortlet
          </Link>
          {/* More Dropdown */}
          <div className="relative inline-block text-left">
            <button
              onClick={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
              className={`text-black font-halant font-semibold py-2 px-5 border-2 border-transparent hover:rounded-3xl hover:text-white hover:border-2 ${
                isMoreMenuOpen
                  ? "bg-green-700 rounded-3xl text-white"
                  : "hover:bg-green-500"
              }`}
            >
              More
            </button>
            {isMoreMenuOpen && (
              <div
                ref={moreMenuRef}
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20 font-halant font-semibold"
              >
                <div
                  className="rounded-md"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {/* Shortlet Link - visible on lg screens and below */}
                  <Link
                    to="/shortlet"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 rounded-sm hover:text-white lg:hidden"
                    role="menuitem"
                  >
                    Shortlet
                  </Link>
                  <Link
                    to="/co-working-space"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 rounded-sm hover:text-white"
                    role="menuitem"
                  >
                    Co-working Space
                  </Link>
                  <Link
                    to="/warehouse"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 rounded-sm hover:text-white"
                    role="menuitem"
                  >
                    Warehouse
                  </Link>
                  <Link
                    to="/event-centre"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 rounded-sm hover:text-white"
                    role="menuitem"
                  >
                    Event Center
                  </Link>
                  <Link
                    to="/office"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 rounded-sm hover:text-white"
                    role="menuitem"
                  >
                    Office
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Login and Signup Buttons */}
      {!isLoggedIn && (
        <div className="md:flex space-x-4 hidden">
          {!noIconRoutes.includes(location.pathname) && (
            <Link to="/signup">
              <button className="bg-green-700 border-2 text-sm border-green-700 text-white py-2 px-7 rounded-3xl font-semibold hover:bg-green-800">
                Sign Up
              </button>
            </Link>
          )}
          {!noIconRoutes.includes(location.pathname) && (
            <Link to="/login">
              <button className="border-2 border-green-700 text-sm text-green-700 py-2 px-7 rounded-3xl font-semibold hover:bg-green-100">
                Log In
              </button>
            </Link>
          )}
        </div>
      )}

      {/* Profile Menu and Notifications */}
      {isLoggedIn && (
        <div className="relative flex items-center space-x-4">
          {!noIconRoutes.includes(location.pathname) && (
            <>
              {/* Profile Menu Toggle */}
              <button
                className="rounded-full h-10 w-10 flex items-center justify-center"
                onClick={toggleMenu}
              >
                {isMenuOpen ? (
                  <CloseRoundedIcon style={{ fontSize: "25px" }} />
                ) : (
                  <img
                    src={profileImage}
                    alt="Owner's Avatar"
                    className="w-[25px] h-[25px] rounded-full object-cover"
                  />
                )}
              </button>

              {/* Notification Button */}
              <Badge badgeContent={unreadCount} color="error">
                <button
                  onClick={toggleNotifications}
                  className="relative focus:outline-none"
                  aria-label="Show notifications"
                >
                  <FiBell style={{ fontSize: "25px" }} />
                </button>
              </Badge>

              {/* Notifications Panel */}
              {isNotificationsOpen && (
                <ClickAwayListener
                  onClickAway={() => setIsNotificationsOpen(false)}
                >
                  <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg shadow-green-700 z-20 top-[150%]">
                    <div className="p-4 border-b">
                      <h3 className="text-lg font-semibold">Notifications</h3>
                    </div>
                    <div className="max-h-80 overflow-y-auto">
                      {notifications.length > 0 ? (
                        notifications.map((notification) => (
                          <div
                            key={notification.id}
                            className={`p-4 border-b hover:bg-gray-100 ${
                              !notification.read ? "bg-gray-50" : ""
                            }`}
                          >
                            <Link
                              to={notification.link || "#"}
                              className="block"
                            >
                              <p className="text-sm text-gray-700">
                                {notification.message}
                              </p>
                              <span className="text-xs text-gray-500">
                                {new Date(
                                  notification.createdAt
                                ).toLocaleString()}
                              </span>
                            </Link>
                          </div>
                        ))
                      ) : (
                        <div className="p-4 text-center text-gray-500">
                          No notifications.
                        </div>
                      )}
                    </div>
                    <div className="p-4 text-right">
                      <Link
                        to="/notifications"
                        className="text-sm text-green-700 hover:underline"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </>
          )}

          {/* Profile Menu Dropdown */}
          {isMenuOpen && (
            <div
              ref={profileRef}
              className="absolute -right-3 w-60 bg-white rounded-sm shadow-lg shadow-green-700 z-10 top-[152%]"
            >
              <div>
                <div>
                  <Link to="/profile">
                    <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                      <AccountCircleOutlinedIcon className="mr-2" />
                      Profile
                    </h2>
                  </Link>
                  {isLoggedIn && (
                    <Link to="userDashboard">
                      <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                        <DashboardOutlined className="mr-2" />
                        Dashboard
                      </h2>
                    </Link>
                  )}
                  <Link to="/settings">
                    <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                      <SettingsOutlinedIcon className="mr-2" />
                      Account Settings
                    </h2>
                  </Link>
                  <Link
                    to="#"
                    onClick={() => setIsFundModalOpen(true)}
                    className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm"
                  >
                    <WalletOutlined className="mr-2" />
                    Fund Wallet
                  </Link>
                  <Link to="/manage-houses">
                    <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                      <ManageAccountsOutlined className="mr-2" />
                      Manage Houses
                    </h2>
                  </Link>
                  <Link to="/chat/:user1/:user2">
                    <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                      <ChatOutlined className="mr-2" />
                      Chats
                    </h2>
                  </Link>
                  <Link to="/favourites">
                    <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                      <FavoriteBorderOutlined className="mr-2" />
                      Favourites
                    </h2>
                  </Link>
                  <Link to="/sell">
                    <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                      <VerifiedOutlinedIcon className="mr-2" />
                      Become a Verified Seller
                    </h2>
                  </Link>
                  <Link to="/support">
                    <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                      <HeadsetMicOutlinedIcon className="mr-2" />
                      Customer Support
                    </h2>
                  </Link>
                  {verificationStatus === 1 && (
                    <Link to="/kycadmin">
                      <h2 className="font-halant font-semibold text-base flex items-center p-2 text-gray-700 hover:text-white hover:bg-green-700 rounded-sm">
                        <AdminPanelSettingsOutlined className="mr-2" />
                        KYC Admin
                      </h2>
                    </Link>
                  )}
                  <Link
                    to="/"
                    className="flex items-center justify-center align-bottom"
                  >
                    {isLoggedIn ? (
                      <button
                        onClick={logout}
                        className="flex py-2 px-16 my-3 rounded-3xl bg-green-700"
                      >
                        <h2 className="font-halant font-semibold text-base text-white">
                          Log Out
                        </h2>
                        <LogoutOutlinedIcon
                          className="ml-2 mt-[2px] text-white"
                          style={{ fontSize: "17px" }}
                        />
                      </button>
                    ) : (
                      <>
                        <Link to="/signup" className="mr-4">
                          Sign Up
                        </Link>
                        <Link to="/login">Log In</Link>
                      </>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Reusable FundWalletModal Component */}
      <FundWalletModal
        className="z-50"
        isOpen={isFundModalOpen}
        onClose={() => setIsFundModalOpen(false)}
      />
    </nav>
  );
}

export default Navbar;
