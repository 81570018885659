// src/components/Loading.js

import React from "react";
import logo from "../assets/logo/png/housemelogo_4.png";

const Loading = () => {
  return (
    <div
      id="loading-screen"
      className="fixed inset-0 flex items-center justify-center bg-opacity-75 bg-white animate-background z-[9999]"
    >
      <div className="flex flex-col items-center">
        <img
          src={logo}
          alt="Loading..."
          className="h-14 w-auto animate-bounce"
        />
      </div>
    </div>
  );
};

export default Loading;
