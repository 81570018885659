// src/components/Support.js
import React, { useState } from "react";
import { Link } from "react-router-dom"; // Ensure correct path
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FiSun, FiMoon } from "react-icons/fi";
import { ChatBubbleOutlineOutlined } from "@mui/icons-material";

const Support = () => {
  const [darkMode, setDarkMode] = useState(false);

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <>
    <head>
        <title>HousemeNG | Support</title>
      </head>
    <div className={darkMode ? "dark" : ""}>
      <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-500 font-halant">
        {/* Toggle Dark Mode Button */}
        <button
          onClick={toggleDarkMode}
          className="fixed top-24 right-4 z-50 p-2 rounded-full bg-gray-700 dark:bg-gray-200 text-gray-200 dark:text-gray-800 focus:outline-none"
          aria-label="Toggle Dark Mode"
        >
          {darkMode ? <FiSun size={20} /> : <FiMoon size={20} />}
        </button>

        {/* Hero Section */}
        <section className="relative flex items-center justify-center h-screen overflow-hidden">
          {/* Background */}
          <div className="absolute inset-0 bg-gradient-to-r from-green-700 to-gray-400"></div>

          {/* Overlay Content */}
          <div className="relative z-10 text-center px-4">
            <h1 className="text-5xl md:text-7xl font-extrabold text-white mb-4">
              Need Help?
            </h1>
            <p className="text-xl md:text-2xl text-white mb-8">
              We're here to assist you 24/7
            </p>
            <div className="flex justify-center space-x-4">
              <Link
                to="/chat"
                className="px-6 py-3 bg-white text-green-500 font-semibold rounded-full shadow-lg hover:bg-gray-100 transition"
              >
                Contact Us
              </Link>
              <Link
                to="/faqs"
                className="px-6 py-3 bg-transparent border border-white text-white font-semibold rounded-full hover:bg-white hover:text-green-500 transition"
              >
                FAQs
              </Link>
            </div>
          </div>

          {/* Decorative SVG */}
          <div className="absolute bottom-0 left-0 right-0">
            <svg
              className="w-full h-24 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
            >
              <path
                fill="#ffffff"
                fillOpacity="1"
                d="M0,160L60,144C120,128,240,96,360,90.7C480,85,600,107,720,122.7C840,139,960,149,1080,154.7C1200,160,1320,160,1380,160L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
              ></path>
            </svg>
          </div>
        </section>

        {/* Interactive Support Options */}
        <section className="py-20 bg-gray-50 dark:bg-gray-800">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 dark:text-gray-200 mb-12">
              How Can We Assist You Today?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Support Card 1 */}
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 flex flex-col items-center text-center hover:scale-105 transition-transform duration-300">
                <HelpOutlineIcon className="text-green-500 text-5xl mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">
                  General Inquiries
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  Have questions about our services or need more information?
                  Reach out to us!
                </p>
                <Link
                  to="/contact"
                  className="mt-auto px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-300"
                >
                  Contact Now
                </Link>
              </div>

              {/* Support Card 2 */}
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 flex flex-col items-center text-center hover:scale-105 transition-transform duration-300">
                <PhoneOutlinedIcon className="text-green-500 text-5xl mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">
                  Technical Support
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  Experiencing technical issues? Our support team is here to
                  help you troubleshoot.
                </p>
                <Link
                  to="/technical-support"
                  className="mt-auto px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-300"
                >
                  Get Help
                </Link>
              </div>

              {/* Support Card 3 */}
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 flex flex-col items-center text-center hover:scale-105 transition-transform duration-300">
                <EmailOutlinedIcon className="text-green-500 text-5xl mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">
                  Feedback & Suggestions
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  We value your feedback! Let us know how we can improve our
                  services.
                </p>
                <Link
                  to="/feedback"
                  className="mt-auto px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-300"
                >
                  Give Feedback
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Floating Action Buttons */}
        <div className="fixed bottom-8 right-8 flex flex-col space-y-4 z-50">
          {/* chat Button */}
          <Link
            to="/chat"
            className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white rounded-full h-12 w-12 shadow-lg transition-colors duration-300"
            aria-label="Chat"
          >
            <ChatBubbleOutlineOutlined />
          </Link>

          {/* Email Button */}
          <a
            href="mailto:realhouseme.ng@gmail.com"
            className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white rounded-full h-12 w-12 shadow-lg transition-colors duration-300"
            aria-label="Email Us"
          >
            <EmailOutlinedIcon />
          </a>

          {/* Phone Button */}
          <a
            href="tel:+1234567890"
            className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white rounded-full h-12 w-12 shadow-lg transition-colors duration-300"
            aria-label="Call Us"
          >
            <PhoneOutlinedIcon />
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default Support;
