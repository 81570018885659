import React, { useState, useEffect, memo, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import {
  FaBed,
  FaShower,
  FaRulerCombined,
  FaRegSadCry,
} from "react-icons/fa";
import { MdGarage } from "react-icons/md";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import house from "../assets/images/house.jpg";
import { Helmet } from "react-helmet";

const PropertyCard = memo(function PropertyCard({ property, backendUrl2 }) {
  return (
    <Link
      to={`/propertyDetails/${property.id}`}
      className="block"
      aria-label={`View details of ${property.house_name}`}
    >
      <div className="bg-white shadow-md rounded-3xl overflow-hidden hover:shadow-lg transition-shadow duration-300">
        <div className="relative">
          <img
            src={`${backendUrl2}/${property.images[0]}`}
            alt={`Listing for ${property.tag}`}
            className="w-full h-60 object-cover"
            loading="lazy"
          />
          {/* Property Tag Badge */}
          <span className="absolute top-0 right-11 px-3 py-1 bg-green-700 text-white text-sm font-bold rounded-b-lg mt-5">
            {property.tag?.toUpperCase() || "TAG"}
          </span>
          {/* Property Price Badge */}
          <span className="absolute bottom-0 left-0 px-3 py-1 bg-green-700 text-white text-xl font-bold rounded-tr-lg">
            ₦{parseInt(property.price, 10).toLocaleString()}
          </span>
          {/* Featured Badge (if featured)
          {property.featured && (
            <span className="absolute top-0 left-0 px-3 py-1 bg-yellow-500 text-white text-sm font-bold rounded-br-lg mt-5">
              FEATURED
            </span>
          )} */}
        </div>
        <div className="p-4">
          <h3 className="text-lg font-halant font-bold mb-2 text-gray-800">
            {property.house_name || "Unnamed Property"}
          </h3>
          <p className="text-gray-600 mb-1 font-halant-light">
            {property.state || "Unknown State"},
            {property.location || "Unknown Location"}
          </p>
          <div className="flex space-x-5 items-center mb-2">
            {/* Bedrooms */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.beds ?? 0}</span>
              <FaBed size={20} className="text-green-700" aria-hidden="true" />
            </div>
            {/* Bathrooms */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.baths ?? 0}</span>
              <FaShower
                size={20}
                className="text-green-700"
                aria-hidden="true"
              />
            </div>
            {/* Garages */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.garages ?? 0}</span>
              <MdGarage
                size={20}
                className="text-green-700"
                aria-hidden="true"
              />
            </div>
            {/* Land Area */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.land_area ?? 0} sqft</span>
              <FaRulerCombined
                size={20}
                className="text-green-700"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
});

function Home() {
  const [propertyData, setPropertyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State variables for filters
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { isLoggedIn } = useAuth();

  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  // **New Ref for Search Results**
  const searchResultsRef = useRef(null);

  // **New State Variable for Search Trigger**
  const [isSearched, setIsSearched] = useState(false);

  // **State Variable for Featured Properties**
  const [featuredProperties, setFeaturedProperties] = useState([]);

  // Fetch property data
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/houses/`);
        setPropertyData(response.data);
        // **Extract Featured Properties**
        const featured = response.data.filter((property) => property.featured);
        setFeaturedProperties(featured);
      } catch (error) {
        console.error("Error fetching property data:", error);
        setError("Failed to load properties. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [backendUrl]);

  // Effect to Reset `isSearched` When Inputs Are Cleared
  useEffect(() => {
    if (selectedLocation.trim() === "" && selectedPriceRange.trim() === "") {
      setIsSearched(false);
    }
  }, [selectedLocation, selectedPriceRange]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-gray-600 text-xl">Loading...</p>
      </div>
    );
  }

  // if (error) {
  //   return (
  //     <div className="flex flex-col items-center justify-center h-screen">
  //       <FaRegSadCry size={120} color="gray" aria-hidden="true" />
  //       <h2 className="text-gray-600 text-2xl mt-4">{error}</h2>
  //     </div>
  //   );
  // }

  // if (propertyData.length === 0) {
  //   return (
  //     <div className="flex flex-col items-center justify-center h-screen">
  //       <FaHome size={120} color="gray" aria-hidden="true" />
  //       <h2 className="text-gray-600 text-2xl mt-4">
  //         No Properties Available Yet
  //       </h2>
  //     </div>
  //   );
  // }

  // Filter properties based on selected filters
  const filteredProperties = propertyData.filter((property) => {
    // Filter by location (state)
    const trimmedLocation = selectedLocation.trim().toLowerCase();
    const locationMatch = trimmedLocation
      ? property.state?.toLowerCase().includes(trimmedLocation)
      : true;

    // Filter by price range
    let priceMatch = true;
    if (selectedPriceRange) {
      const price = parseFloat(property.price);
      if (selectedPriceRange === "100k to 200k") {
        priceMatch = price >= 100000 && price <= 200000;
      } else if (selectedPriceRange === "200k to 300k") {
        priceMatch = price >= 200000 && price <= 300000;
      }
    }

    // Filter by property type (tag)
    const typeMatch = selectedType ? property.tag === selectedType : true;

    // Return true if all conditions match
    return locationMatch && priceMatch && typeMatch;
  });

  // **Determine if Search Inputs are Provided**
  const isSearchInputProvided =
    selectedLocation.trim() !== "" ||
    selectedPriceRange.trim() !== "" ||
    selectedType.trim() !== "";

  return (
    <div>
      <Helmet>
        <title>HousemeNG | Home</title>
        <meta name="description" content="My page description." />
      </Helmet>
      <div className="font-halant overflow-x-hidden">
        {/* Search Component */}
        <div className="border w-[100vw] h-[500px] sm:h-[718px] relative flex lg:justify-start items-center justify-center sm:mt-0">
          {/* bg Image */}
          <div className="w-[100%] h-[100%]">
            <img
              src={house}
              alt="House"
              className="w-[100%] h-[100%] object-cover brightness-75"
            />
          </div>
          {/* Search Form */}
          <div className="absolute">
            <div className="text-white text-center">
              <h1 className="sm:text-4xl text-2xl font-halant font-bold mt-20">
                Secure Your Dream Home
              </h1>
              {/* Conditionally render buttons based on login status */}
              {!isLoggedIn && (
                <div className="md:hidden space-x-4 mb-5 flex justify-center">
                  {/* Sign Up Button */}
                  <Link to="/signup">
                    <button className="bg-green-700 border-2 border-green-700 text-white font-bold font-halant py-2 px-7 rounded-3xl hover:bg-green-800">
                      Sign Up
                    </button>
                  </Link>

                  {/* Log In Button */}
                  <Link to="/login">
                    <button className="border-2 border-green-700 text-white py-2 px-7 rounded-3xl font-bold font-halant hover:bg-green-100 hover:text-green-800">
                      Log In
                    </button>
                  </Link>
                </div>
              )}

              {/* Search Functionality */}
              <div className="flex justify-center">
                <div className="mdx:bg-white rounded-full p-3 flex flex-col mdx:flex-row items-center space-y-3 mdx:space-y-0 mdx:space-x-4 lg:ml-[10%]">
                  {/* First Two Inputs */}
                  <div className="flex flex-row mb-3 mdx:mb-0 space-x-5 mdx:space-x-0">
                    <div className="relative flex items-center mb-2 mdx:mb-0 mdx:mr-3">
                      <LocationOnOutlinedIcon className="text-gray-400 absolute left-3" />
                      <input
                        type="text"
                        placeholder="Location"
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        className="pl-8 px-4 py-2 border-t-2 border-b-[1px] bg-white text-black border-gray-300 w-40 h-11 font-halant-light text-base rounded-full focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                      />
                    </div>
                    <select
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                      className="px-4 py-2 border-t-2 border-b-[1px] rounded-full bg-white text-black border-gray-300 w-40 h-11 font-halant-light text-base focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    >
                      <option value="">Property Type</option>
                      <option value="Sale">Sale</option>
                      <option value="Rent">Rent</option>
                      <option value="Shortlet">Shortlet</option>
                    </select>
                  </div>

                  {/* Second Two Inputs */}
                  <div className="flex flex-row mb-3 mdx:mb-0 space-x-5 mdx:space-x-0">
                    <select
                      value={selectedPriceRange}
                      onChange={(e) => setSelectedPriceRange(e.target.value)}
                      className="px-4 py-2 border-t-2 border-b-[1px] rounded-full bg-white text-black border-gray-300 mb-2 sm:mb-0 sm:mr-3 w-40 h-11 font-halant-light text-base focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    >
                      <option value="">Price Range</option>
                      <option value="100k to 200k">100k to 200k</option>
                      <option value="200k to 300k">200k to 300k</option>
                    </select>
                  </div>

                  {/* Search Button */}
                  <div>
                    <div className="flex">
                      <div className="relative">
                        <button
                          onClick={() => {
                            if (isSearchInputProvided) {
                              setIsSearched(true);
                              if (searchResultsRef.current) {
                                searchResultsRef.current.scrollIntoView({
                                  behavior: "smooth",
                                });
                              }
                            }
                          }}
                          disabled={!isSearchInputProvided} // Disable if no input
                          className={`bg-green-700 text-white text-lg px-6 py-2 font-halant font-semibold rounded-3xl pr-10 flex items-center ${
                            !isSearchInputProvided
                              ? "opacity-50 cursor-not-allowed"
                              : "hover:bg-green-800"
                          }`}
                          aria-label="Search properties"
                        >
                          Search
                          <SearchSharpIcon className="text-white absolute right-3 top-1/2 transform -translate-y-1/2 mt-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* **Search Output Section** */}
        <div ref={searchResultsRef} className="py-8 mx-7">
          {isSearched && (
            <>
              {filteredProperties.length > 0 ? (
                <div className="mb-8">
                  <h2 className="text-2xl font-halant font-bold mb-4">
                    We found something for you
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredProperties.map((property) => (
                      <PropertyCard
                        key={property.id}
                        property={property}
                        backendUrl2={backendUrl2}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center mb-8">
                  <FaRegSadCry size={120} color="gray" aria-hidden="true" />
                  <h2 className="text-gray-600 text-2xl mt-4">
                    We currently don't have what you're looking for, but you can
                    check these out:
                  </h2>
                </div>
              )}
            </>
          )}
        </div>

        {/* **Featured Properties Section** */}
        {/* <div className="py-8 mx-7">
          <div className="lg:mx-2 sm:mx-0 xsm:mx-[10%] mx-0">
            <h2 className="text-xl font-halant font-bold mb-1">
              Featured Properties
            </h2>
            <h2 className="text-lg font-halant-light mb-5">
              Premium listings highlighted for you
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {featuredProperties.length > 0 ? (
                featuredProperties.map((property) => (
                  <PropertyCard
                    key={property.id}
                    property={property}
                    backendUrl2={backendUrl2}
                  />
                ))
              ) : (
                <p className="text-gray-500">
                  No featured properties available.
                </p>
              )}
            </div>
          </div>
        </div> */}

        {/* **Trending Properties Section** */}
        <div className="py-8 mx-7">
          <div className="lg:mx-2 sm:mx-0 xsm:mx-[10%] mx-0">
            <h2 className="text-xl font-halant font-bold mb-1">
              Trending properties in Lagos, NG
            </h2>
            <h2 className="text-lg font-halant-light mb-5">
              Based on top user searches in the last hour
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {isSearched
                ? propertyData
                    .filter(
                      (property) =>
                        !filteredProperties.find(
                          (fp) => fp.id === property.id
                        ) && !property.featured // Exclude filtered and featured properties
                    )
                    .map((property) => (
                      <PropertyCard
                        key={property.id}
                        property={property}
                        backendUrl2={backendUrl2}
                      />
                    ))
                : propertyData.map((property) => (
                    <PropertyCard
                      key={property.id}
                      property={property}
                      backendUrl2={backendUrl2}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
