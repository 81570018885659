// AuthContext.js

import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const avatar = localStorage.getItem("avatar");
    if (userId) {
      setIsLoggedIn(true);
      setProfileImage(avatar);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "userId") {
        if (event.newValue) {
          // If userId is added or updated in another tab
          setIsLoggedIn(true);
          const avatar = localStorage.getItem("avatar");
          setProfileImage(avatar);
        } else {
          // If userId is removed (logout) in another tab
          setIsLoggedIn(false);
          setProfileImage(null);
        }
      }

      if (event.key === "avatar") {
        if (event.newValue) {
          setProfileImage(event.newValue);
        } else {
          setProfileImage(null);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const login = (userId, avatar) => {
    localStorage.setItem("userId", userId);
    if (avatar) {
      localStorage.setItem("avatar", avatar);
      setProfileImage(avatar);
    }
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("avatar");
    setIsLoggedIn(false);
    setProfileImage(null);
    // Removed window.location.reload() to allow state updates without reload
  };

  const updateProfileImage = (newImage) => {
    setProfileImage(newImage);
    localStorage.setItem("avatar", newImage);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        loading,
        login,
        logout,
        profileImage,
        updateProfileImage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
