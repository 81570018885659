// src/components/Login.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "./AuthContext";
import house from "../assets/images/Frame 1618868479.jpg";
import { FaUser, FaEye, FaEyeSlash, FaRegSadCry } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import avatar from "../assets/images/avatar.png";

const Login = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim the identifier and password to remove leading/trailing spaces
    const trimmedIdentifier = identifier.trim();
    const trimmedPassword = password.trim();

    const loginData = {
      identifier: trimmedIdentifier,
      password: trimmedPassword,
    };

    try {
      const result = await axios.post(`${backendUrl}/auth/login`, loginData);
      const { token, user } = result.data;

      localStorage.setItem("authToken", token);
      localStorage.setItem("firstName", user.firstName);
      localStorage.setItem("lastName", user.lastName);
      localStorage.setItem("userId", user.id);
      localStorage.setItem("email", user.email);
      localStorage.setItem("role", user.role);

      const avatarUrl = user.photo ? `${backendUrl2}/${user.photo}` : avatar;
      login(user.id, avatarUrl);

      navigate("/home");
    } catch (err) {
      setError(err.response?.data?.msg || "Login failed. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <head>
        <title>HousemeNG | Login</title>
      </head>
      <div className="flex flex-col md:flex-row min-h-screen bg-gray-100 dark:bg-gray-900 relative">
        <div className="hidden md:block md:w-1/2 h-full fixed left-0 top-0">
          <img
            src={house}
            alt="Building"
            className="object-cover w-full h-full"
          />
        </div>

        <div className="flex flex-col justify-center w-full md:w-1/2 px-6 md:px-16 lg:px-2 min-h-screen mt-28 md:ml-[50%] bg-gray-100">
          <div className="mx-auto w-full max-w-md">
            <div className="flex justify-center mb-6">
              <FaUser className="text-green-700 w-16 h-16" />
            </div>
            <h2 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
              Welcome Back
            </h2>
            <p className="text-sm text-center text-gray-600 dark:text-gray-300 mt-2">
              Enter your credentials to access your account
            </p>

            {error && (
              <div className="bg-red-100 text-red-700 px-4 py-3 rounded mt-6 flex items-center">
                <FaRegSadCry className="mr-2" />
                <span>{error}</span>
              </div>
            )}

            <form onSubmit={handleSubmit} className="mt-8">
              <div>
                <label
                  htmlFor="identifier"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Username or Email Address
                </label>
                <input
                  type="text"
                  id="identifier"
                  required
                  placeholder="Enter your username or email"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 text-gray-900 dark:text-gray-100"
                  value={identifier}
                  onChange={(e) => setIdentifier(e.target.value)}
                />
              </div>

              <div className="mt-6 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  required
                  placeholder="Your password"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 text-gray-900 dark:text-gray-100"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer align-middle h-[135%]"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FaEyeSlash className="text-gray-500" />
                  ) : (
                    <FaEye className="text-gray-500" />
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between mt-6">
                <Link
                  to="/forgotPassword"
                  className="text-sm text-green-700 dark:text-green-400 hover:underline"
                >
                  Forgot Password?
                </Link>
              </div>

              <button
                type="submit"
                className="mt-6 w-full py-3 bg-green-700 hover:bg-green-800 text-white font-semibold rounded-md shadow focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                Sign In
              </button>
            </form>

            <div className="flex items-center my-6">
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-700" />
              <span className="mx-4 text-gray-500 dark:text-gray-400">OR</span>
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-700" />
            </div>

            <button className="w-full py-3 flex items-center justify-center bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              <FcGoogle className="w-6 h-6 mr-2" />
              Continue with Google
            </button>

            <p className="mt-6 text-center text-sm text-gray-600 dark:text-gray-400 mb-16">
              <span className="mr-1">New user?</span>
              <Link
                to="/signup"
                className="text-green-700 dark:text-green-400 hover:underline"
              >
                <span>Sign up for an account</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
